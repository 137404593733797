exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-delete-data-js": () => import("./../../../src/pages/delete-data.js" /* webpackChunkName: "component---src-pages-delete-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-confirmed-js": () => import("./../../../src/pages/payment-confirmed.js" /* webpackChunkName: "component---src-pages-payment-confirmed-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-test-android-js": () => import("./../../../src/pages/test-android.js" /* webpackChunkName: "component---src-pages-test-android-js" */),
  "component---src-pages-test-iphone-js": () => import("./../../../src/pages/test-iphone.js" /* webpackChunkName: "component---src-pages-test-iphone-js" */)
}

